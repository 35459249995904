var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('div', {
    staticClass: "visual fade mt-50 mt-lg-0"
  }, [_c('v-container', [_c('div', {
    staticClass: "visual-ani-group text-center color-white"
  }, [_c('div', {
    staticClass: "visual-ani"
  }, [_c('p', [_vm._v("당신이 "), _c('strong', [_vm._v("별")]), _vm._v("이 되는 곳, 당신의 "), _c('strong', [_vm._v("꿈")]), _vm._v("이 실현되는 곳")])]), _c('div', {
    staticClass: "visual-ani"
  }, [_c('h2', [_vm._v("재단법인 유더스타스포츠")])]), _c('div', {
    staticClass: "visual-ani"
  }, [_c('div', {
    staticClass: "button-wrap d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "min-w-180px min-w-lg-240px",
    attrs: {
      "to": "/about/notice",
      "large": "",
      "outlined": "",
      "color": "white"
    }
  }, [_c('span', {
    staticClass: "d-flex justify-space-between align-center w-100"
  }, [_vm._v(" U the STAR "), _c('i', {
    staticClass: "icon icon-right-arrow"
  })])])], 1)])])])], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }