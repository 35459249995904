var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "quick-wrap"
  }, [_c('a', {
    staticClass: "quick-button",
    attrs: {
      "href": "/donation/donation"
    }
  }, [_c('div', {
    staticClass: "circle-left"
  }, [_c('span')]), _c('div', {
    staticClass: "circle-right"
  }, [_c('span')]), _c('p', [_vm._v(" 기부 "), _c('br'), _vm._v(" 후원 ")])])]);

}]

export { render, staticRenderFns }