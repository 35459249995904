<template>
    <div class="quick-wrap">
        <a href="/donation/donation" class="quick-button">
            <div class="circle-left"><span></span></div>
            <div class="circle-right"><span></span></div>
            <p>
                기부 <br>
                후원
            </p>
        </a>
    </div>
</template>

<script>

export default {
    components: {
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped>
/*quick*/
.quick-wrap{
    position: fixed;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    width: 86px;
    height: 86px;
    border-radius: 50%;
    z-index: 10;
    opacity: 0;
    overflow: hidden;
    animation: quickFade 0.6s 2s ease-in-out forwards;
}

@keyframes quickFade {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.quick-button{
    position: relative;
    width: 100%;
    height: 100%;;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(17, 17, 17, 0.6);
    transition: 0.3s ease;
    border-radius: 50%;
}

.quick-button p{
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-weight: 700;
}

.circle-right{
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.circle-right span{
    display: block;
    width: 100%;
    height: 100%;
    animation: right 0.75s 2.6s forwards linear;
    transform: rotate(-180deg);
    transform-origin: 0 center;
    border: 2px solid var(--v-primary-base);
    border-left: none;
    border-radius: 0 43px 43px 0;}

@keyframes right{
    0%{
        transform: rotate(-180deg);
    }
    40%{
        transform: rotate(0deg);
    }
    80%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

.circle-left{
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.circle-left span{display: block;
    width: 100%;
    height: 100%;
    animation: left 0.75s 2.6s forwards linear;
    transform: rotate(-180deg);
    transform-origin: right center;
    border: 2px solid var(--v-primary-base);
    border-right: none;
    border-radius: 43px 0 0 43px;}

@keyframes left{
    0%{
        transform: rotate(-180deg);
    }
    40%{
        transform: rotate(-180deg);
    }
    80%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(0);
    }
}

@media (min-width:1024px){
    .quick-button:hover{
        background-color: var(--v-primary-base);
    }
}

@media (max-width: 1200px){

}
@media (max-width: 1024px){
    .quick-wrap{
        position: fixed;
        top: unset;
        bottom: 30px;
        right: 15px;
        transform: translateY(0);
        width: 56px;
        height: 56px;;
    }

    .quick-button p{
        font-size: 12px;
        line-height: 14px;
    }
}
@media (max-width: 768px){

}
</style>