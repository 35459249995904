<template>
    <client-page class="app--main">

        <div class="visual fade mt-50 mt-lg-0">
            <v-container>
                <div class="visual-ani-group text-center color-white">
                    <div class="visual-ani">
                        <p>당신이 <strong>별</strong>이 되는 곳, 당신의 <strong>꿈</strong>이 실현되는 곳</p>
                    </div>
                    <div class="visual-ani">
                        <h2>재단법인 유더스타스포츠</h2>
                    </div>
                    <div class="visual-ani">
                        <div class="button-wrap d-flex justify-center">
                            <v-btn to="/about/notice" large outlined color="white" class="min-w-180px min-w-lg-240px">
                                <span class="d-flex justify-space-between align-center w-100">
                                    U the STAR
                                    <i class="icon icon-right-arrow"></i>
                                </span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-container>
        </div>

        <popup-layer></popup-layer>

    </client-page>
</template>

<script>
import api from "@/api"
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import MainBanners from "@/components/client/ko/main/main-banners.vue";
import MainSection from "@/components/client/ko/main/main-section.vue";
// import MainVisual from "@/components/client/ko/main/main-visual.vue";
import MainTextBanner from "@/components/client/ko/main/main-text-banner.vue";
import MainAuctionsBanner from "@/components/client/ko/main/main-auctions-banner.vue";
// import BoardList from "@/components/client/ko/board/board-list.vue";
import PopupLayer from '@/components/client/ko/popup/popup-layer.vue';
import LocaleSelect from "@/components/client/ko/inc/locale-select.vue";
import { BOARD_YOUTUBE_CATEGORIES, BOARD_REPORT_CATEGORIES, BOARD_FORMS_CATEGORIES } from '@/assets/variables';

const categoriesYoutube = Object.values(BOARD_YOUTUBE_CATEGORIES)
const categoriesReport = Object.values(BOARD_REPORT_CATEGORIES)
const categoriesForms = Object.values(BOARD_FORMS_CATEGORIES)

export default {
    components: {
        ClientPage,
        MainBanners,
        MainSection,
        // MainVisual,
        MainTextBanner,
        MainAuctionsBanner,
        // BoardList,
        PopupLayer,
        LocaleSelect
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
    data: () => ({
        categoriesYoutube,
        categoriesReport,
        categoriesForms,

        filter: {
            language: "ko",
            code: "partners",
            category: "국내"
        },

        partners: []
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                // tab
                (function tabList(){
                    const tabList = document.querySelectorAll('.tab-button li'),
                    contentsList = document.querySelectorAll('.tab-contents li'),
                    tab = document.querySelectorAll('.tab-button li button');

                    if(tabList !== undefined){
                        for(let i = 0; i < tab.length; i++){
                            tab[i].addEventListener('click', function(){
                                tabMenuToggle(i);

                                if(contentsList[i] !== undefined) tabContentsToggle(i);
                            });
                        }
                    }

                    function tabMenuToggle(current){
                        for(let i = 0; i < tab.length; i++)
                            tabList[i].classList.remove('on');

                        tabList[current].classList.add('on');
                    }
                    function tabContentsToggle(current){
                        for(let i = 0; i < tab.length; i++)
                            contentsList[i].classList.remove('on');

                        contentsList[current].classList.add('on');
                    }
                })();
            });
            await this.getBoards('partners', '국내')
        },
        async getBoards(code, category){
            this.filter.code = code
            this.filter.category = category

            let { boards: partners } = await api.v1.boards.gets({
                params: { ...this.filter }
            })
            this.partners = partners
        }
    },
};
</script>

<style scoped>
.s2-left-item{
    background-image: url(/images/main/s2-img.png);
    background-position: center;
    background-size: cover;
}
.s2-right-logo{
    height: 44px;
}

.tab-contents { position: relative; }
.tab-contents > li { display: none; position: absolute; top: 0; left: 0; }
.tab-contents > li.on { display: block; height:auto; position:relative; animation: contents-on ease-in-out .5s forwards; }
@-webkit-keyframes contents-on { from { opacity: 0; } to { opacity: 1; } }
@keyframes contents-on { from { opacity: 0; } to { opacity: 1; } }

.tab-button li button{
    position: relative;
    transition: 0.2s ease-out;
}

.tab-button li.on button{
    color: var(--v-primary-base);
}

.tab-button li button::after{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: var(--v-primary-base);
    left: 0;
    bottom: -12px;
    transform: scaleY(0);
    transform-origin: center bottom;
    transition: 0.2s ease-out;
}

.tab-button li.on button::after{
    transform: scaleY(1);
}

.apply-thum-wrap{
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc((100 / 273) * 100%);
    overflow: visible;
}

.apply-thum{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}

.apply-link{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.2s ease-out;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .s2-right-logo{
        height: 48px;
    }
    .s3-item{
        height: 252px;
    }

    .tab-button li button:hover{
        color: var(--v-primary-base);
    }
    .apply-link:hover{
        box-shadow: 0 0 30px rgba(14, 20, 48, 0.05);
    }
}
@media (min-width:1200px){
}

</style>