import axios from "@/plugins/axios";

let url = "/api/console/boards/settings";

export default {
    get(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    post(data) {
        console.log(data)
        return axios.post(url, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(url, data).then((result) => result.data);
    }
};
