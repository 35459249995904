<template>
    <v-app>
        <!-- S: HEADER -->
        <main-header></main-header>
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <div id="contents">
                <slot />
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer></main-footer>
        <!-- E: FOOTER -->

        <!-- <u-scroll-top></u-scroll-top> -->

        <quick-menu></quick-menu>

    </v-app>
</template>

<script>
import MainHeader from "@/components/client/ko/inc/main-header.vue";
import MainFooter from "@/components/client/ko/inc/main-footer.vue";
import QuickMenu from "@/components/client/ko/inc/quick-menu.vue";
// import UScrollTop from "@/components/dumb/u-scroll-top.vue";
export default {
    props: {
        isMain: { type: Boolean, default: true },
    },
    components: {
        MainHeader,
        MainFooter,
        // UScrollTop,
        QuickMenu,
    }
};
</script>
