<template>
    <footer class="footer">
        <div class="footer__top py-60 py-lg-80">
            <v-container class="container--large">

                <UScrollTop/>

                <v-row>
                    <v-col cols="12" lg="4">
                        <div class="footer-logo mb-20 mb-lg-40"></div>
                        <div class="d-flex">
                            <a href="https://blog.naver.com/uthestar" target="_blank" class="footer-sns footer-sns__blog mr-6 mr-lg-12"></a>
                            <a href="https://www.instagram.com/uthestarsports/" target="_blank" class="footer-sns footer-sns__instar"></a>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <p class="color-grey mb-14 mb-lg-32">
                            <strong class="font-weight-bold">재단법인 유더스타스포츠</strong><br>
                            Address. 서울특별시 강서구 공항대로 39길 7(구. 등촌동 669-2)<span class="d-none d-xl-inline-block text-center" style="width: 36px; color: #ddd;">l</span><br class="d-block d-xl-none">
                            Tel. 02-3662-3210<span class="d-none d-xl-inline-block text-center" style="width: 36px; color: #ddd;">l</span><br class="d-block d-xl-none">
                            Fax. 02-3664-2401<br>
                            사업자등록번호. 867-82-00369<span class="d-none d-xl-inline-block text-center" style="width: 36px; color: #ddd;">l</span><br class="d-block d-xl-none">
                            개인정보책임자. maruyou@uthestar.org
                        </p>
                        <div class="d-flex mx-n4 mx-lg-n6 footer-cert-group">
                            <div class="px-4 px-lg-6">
                                <img src="/images/common/cert1.jpg" alt="" class="d-block">
                            </div>
                            <div class="px-4 px-lg-6">
                                <img src="/images/common/cert2.jpg" alt="" class="d-block">
                            </div>
                            <div class="px-4 px-lg-6">
                                <img src="/images/common/cert3.jpg" alt="" class="d-block">
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="footer__bottom py-22">
            <v-container class="container--large">
                <v-row>
                    <v-col cols="12" lg="4">
                        <div class="d-flex align-center">
                            <a href="/about/business" class="d-block font-weight-bold color-grey mr-10 mr-lg-18">재단 소개</a>
                            <span style="color: #ddd;">l</span>
                            <a href="/privacy-policy" class="d-block font-weight-bold color-grey ml-10 ml-lg-18">개인정보처리방침</a>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <p class="color-grey-9">Copyright 2022. 재단법인 유더스타스포츠. all rights reserced.</p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </footer>
</template>

<script>
import UScrollTop from "@/components/dumb/u-scroll-top.vue";
export default {
    components: {
        UScrollTop,
    },
}
</script>

<style scoped>
/* Footer */
.footer{
    border-top: 6px solid var(--v-secondary-base);
    font-size: 16px;
}

.footer__top{
    border-bottom: 1px solid #e6e6e6;
}

.footer__top .container{
    position: relative;
}

.footer-logo{
    width: 261px;
    height: 40px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(/images/footer-ci.png);
}

.footer-sns{
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #f7f8f9;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.footer-sns__blog{
    background-image: url(/images/icon/icon-blog.png);
}

.footer-sns__instar{
    background-image: url(/images/icon/icon-instar.png);
}

.footer__top p{
    line-height: 30px;
}

.footer-cert-group img{
    width: 102px;
}

@media (max-width: 1200px){

}
@media (max-width: 1024px){
    .footer{
        border-top: 3px solid var(--v-secondary-base);
        font-size: 14px;
    }

    .footer-logo{
        width: 230px;
        height: 35px;
    }

    .footer-sns{
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f7f8f9;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .footer__top p{
        line-height: 24px;
    }
}
@media (max-width: 768px){

}
</style>
