<template>
    <header class="header">
        <div class="header__inner">
            <h1 class="header__logo">
                <a href="/" title="홈으로 이동"><span class="blind">재단법인 유더스타스포츠</span></a>
            </h1>
            <div class="header__contents">
                <div class="header__menu">
                    <ul class="header-menu-ul">
                        <li>
                            <a class="menu-link menu-toggle" href="/about/notice"><h2>U the STAR</h2></a>
                            <ul class="sub-menu">
                                <li><a href="/about/notice">공지사항</a></li>
                                <li><a href="/about/business">재단소개</a></li>
                                <li><a href="/about/gallery">활동사진</a></li>
                            </ul>
                        </li>
                        <li>
                            <a class="menu-link menu-toggle" href="/center/square"><h2>시설 및 참여</h2></a>
                            <ul class="sub-menu">
                                <li><a href="/center/square">시설소개</a></li>
                                <li><a href="/center/staff1">코칭스탭</a></li>
                                <li><a href="/center/seoul">프로그램 안내</a></li>
                            </ul>
                        </li>
                        <li>
                            <a class="menu-link" href="/inquiry"><h2>문의하기</h2></a>
                        </li>
                        <li>
                            <a class="menu-link menu-toggle" href="/donation/donation"><h2>기부 및 후원하기</h2></a>
                            <ul class="sub-menu">
                                <li><a href="/donation/donation">기부 및 후원</a></li>
                                <!-- <li><a href="/donation/firm">유쾌한 상회</a></li> -->
                                <!-- <li><a href="/campaign/salmon-campaign-donation">[연.어.다.음] 캠페인</a></li> -->
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="header__right">
                    <div class="header-link">
                        <button class="burger-button" type="button" aria-pressed="false" title="풀 메뉴 토글"></button>
                    </div>
                </div>

                <div class="header-cert-wrap">
                    <v-row class="mx-n4">
                        <v-col cols="4" class="px-4">
                            <img src="/images/common/cert1.jpg" alt="" class="d-block w-100" />
                        </v-col>
                        <v-col cols="4" class="px-4">
                            <img src="/images/common/cert2.jpg" alt="" class="d-block w-100" />
                        </v-col>
                        <v-col cols="4" class="px-4">
                            <img src="/images/common/cert3.jpg" alt="" class="d-block w-100" />
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>

        <div class="header-fullmenu">
            <div class="header-fullmenu__contents">
                <div class="header-fullmenu-header">
                    <div class="header-fullmenu-header__inner">
                        <button class="full-menu-close"></button>
                    </div>
                </div>
                <div class="header-fullmenu__inner">
                    <v-row no-gutters class="h-100">
                        <v-col cols="3" class="h-100">
                            <div class="fullmenu-ul-wrap">
                                <div class="fullmenu-ul-contents">
                                    <h3>U the STAR</h3>
                                    <ul class="fullmenu-ul">
                                        <li><a href="/about/notice">공지사항</a></li>
                                        <li><a href="/about/business">재단소개</a></li>
                                        <li><a href="/about/gallery">활동사진</a></li>
                                    </ul>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="3" class="h-100">
                            <div class="fullmenu-ul-wrap">
                                <div class="fullmenu-ul-contents">
                                    <h3>시설 및 참여</h3>
                                    <ul class="fullmenu-ul">
                                        <li><a href="/center/square">시설소개</a></li>
                                        <li><a href="/center/staff1">코칭스탭</a></li>
                                        <li><a href="/center/seoul">프로그램 안내</a></li>
                                    </ul>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="3" class="h-100">
                            <div class="fullmenu-ul-wrap">
                                <div class="fullmenu-ul-contents">
                                    <h3><a href="/inquiry">문의하기</a></h3>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="3" class="h-100">
                            <div class="fullmenu-ul-wrap">
                                <div class="fullmenu-ul-contents">
                                    <h3>기부 및 후원하기</h3>
                                    <ul class="fullmenu-ul">
                                        <li><a href="/donation/donation">기부 및 후원</a></li>
                                        <li><a href="/donation/firm">유쾌한 상회</a></li>
                                        <li><a href="/campaign/salmon-campaign-donation">[연.어.다.음] 캠페인</a></li>
                                    </ul>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>

        <div class="mobile-menu">
            <button class="burger-button burger-button--flip" type="button" aria-pressed="false">
                <span></span>
            </button>
        </div>
    </header>
</template>

<script>
export default {
    components: {},
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                /* Mobile Burger Menu */
                let header = document.querySelector(".header"),
                    burgerMenu = document.querySelector(".mobile-menu .burger-button"),
                    headerMenuLink = document.querySelectorAll(".menu-toggle");

                function hideSubMenu() {
                    for (let i = 0; i < headerMenuLink.length; i++) headerMenuLink[i].setAttribute("aria-pressed", "false");
                }

                function clickSubMenu() {
                    for (let i = 0; i < headerMenuLink.length; i++) {
                        headerMenuLink[i].addEventListener("click", function() {
                            if (this.getAttribute("aria-pressed") === "true") {
                                hideSubMenu();
                            } else {
                                hideSubMenu();
                                this.setAttribute("aria-pressed", "true");
                            }
                        });
                    }
                }
                clickSubMenu();

                function bugerMaker(e) {
                    if (e === undefined) {
                        header.setAttribute("data-header-mobile", "off");
                        burgerMenu.setAttribute("aria-pressed", "false");
                        hideSubMenu();
                        for (let i = 0; i < headerMenuLink.length; i++) headerMenuLink[i].removeAttribute("onclick");
                    } else if (e.getAttribute("aria-pressed") === "false") {
                        e.setAttribute("aria-pressed", "true");
                        header.setAttribute("data-header-mobile", "on");
                        for (let i = 0; i < headerMenuLink.length; i++) headerMenuLink[i].setAttribute("onclick", "return false;");
                    } else {
                        header.setAttribute("data-header-mobile", "off");
                        burgerMenu.setAttribute("aria-pressed", "false");
                        hideSubMenu();
                        for (let i = 0; i < headerMenuLink.length; i++) headerMenuLink[i].removeAttribute("onclick");
                    }
                }

                burgerMenu.addEventListener("click", function() {
                    bugerMaker(this);
                });

                // Full Menu
                const fullButton = document.querySelector(".header-link .burger-button");
                const fullCloseButton = document.querySelector(".header-fullmenu .full-menu-close");

                function fullmenu() {
                    this.setAttribute("aria-pressed", "true");
                    header.setAttribute("data-header-full", "on");
                }
                function fullMenuClose() {
                    this.setAttribute("aria-pressed", "false");
                    header.setAttribute("data-header-full", "off");
                }

                fullButton.addEventListener("click", fullmenu);
                fullCloseButton.addEventListener("click", fullMenuClose);
            });
        },
    },
};
</script>
