import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/ko/Main.vue";
import { BoardRoutes } from "./board.router";
import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },

    // Sub 페이지
    // U the STAR
    {
        // 공지사항
        path: "/about/notice",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/about/NoticePage.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/about/notice/:_notification",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/about/NoticePage.vue"),
        props: true,
    },
    {
        // 재단사업소개
        path: "/about/business",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/about/BusinessPage.vue"),
        props: true,
    },
    {
        // 지사소개
        path: "/about/own",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/about/OwnPage.vue"),
        props: true,
    },
    {
        // 이사장 인사말
        path: "/about/greeting",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/about/GreetingPage.vue"),
        props: true,
    },
    {
        // 활동사진
        path: "/about/gallery",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/about/GalleryPage.vue"),
        props: true,
    },
    {
        // 활동사진
        path: "/about/gallery/:_board",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/about/GalleryPage.vue"),
        props: true,
    },
    //시설 및 참여
    {
        // 시설소개 - 스퀘어
        path: "/center/square",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/SquarePage.vue"),
        props: true,
    },
    {
        // 시설소개 - 필드
        path: "/center/field",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/FieldPage.vue"),
        props: true,
    },
    {
        // 시설소개 - 그라운드
        path: "/center/ground",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/GroundPage.vue"),
        props: true,
    },
    {
        // 시설소개 - 대만
        path: "/center/taiwan",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/TaiwanPage.vue"),
        props: true,
    },
    {
        // 코칭스탭 - 현남수
        path: "/center/staff1",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/StaffPage1.vue"),
        props: true,
    },
    {
        // 코칭스탭 - 박상혁
        path: "/center/staff2",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/StaffPage2.vue"),
        props: true,
    },
    {
        // 코칭스탭 - 김원진
        path: "/center/staff3",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/StaffPage3.vue"),
        props: true,
    },
    {
        // 코칭스탭 - 홍승우
        path: "/center/staff4",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/StaffPage4.vue"),
        props: true,
    },
    {
        // 코칭스탭 - 이정훈
        path: "/center/staff5",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/StaffPage5.vue"),
        props: true,
    },
    {
        // 코칭스탭 - 김성민
        path: "/center/staff6",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/StaffPage6.vue"),
        props: true,
    },
    // 프로그램 안내
    {
        path: "/center/program",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/ProgramPage.vue"),
        props: true,
    },
    // {
    //     // 서울본점
    //     path: "/center/seoul",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/SeoulPage.vue"),
    //     props: true,
    // },
    // {
    //     // 청라지사
    //     path: "/center/cheongna",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/center/CheongnaPage.vue"),
    //     props: true,
    // },
    //문의하기
    {
        path: "/inquiry",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/inquiry/InquiryPage.vue"),
        props: true,
    },
    //기부 및 후원하기
    {
        //기부 및 후원
        path: "/donation/donation",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/donation/DonationPage.vue"),
        props: true,
    },
    // {
    //     //유쾌한 상회
    //     path: "/donation/firm",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/donation/FirmPage.vue"),
    //     props: true,
    // },
    // {
    //     // 연어다음 캠페인 - 연어먹고 후원신청
    //     path: "/campaign/salmon-campaign-donation",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/campaign/salmon-campaign-donation.vue"),
    //     props: true,
    // },
    // {
    //     // 연어다음 캠페인 - 제품 상세정보
    //     path: "/campaign/salmon-campaign-information",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/campaign/salmon-campaign-information.vue"),
    //     props: true,
    // },
    {
        // 리소스 라이브러리 뷰페이지
        path: "/resoures/library/:id",
        component: () => import(/* webpackChunkName: "board" */ "../pages/client/ko/board/LibraryView.vue"),
        props: true,
    },
    {
        // 리소스 커스텀 뷰페이지
        path: "/resoures/customer/:id",
        component: () => import(/* webpackChunkName: "board" */ "../pages/client/ko/board/CustomerView.vue"),
        props: true,
    },
    {
        // 개인정보처리방침
        path: "/privacy-policy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/center/PrivacyPolicyPage.vue"),
        props: true,
    },
    {
        // 제3자 제공동의
        path: "/privacy-use",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/center/PrivacyUsePage.vue"),
        props: true,
    },
    {
        // 가이드
        path: "/guide",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/ko/sub/Guide.vue"),
        props: true,
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ko/BoardList.vue"),
    },
    {
        // 관리자 - 게시판 타이틀
        path: "/console/boards/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardSettingPage.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ko/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/ko/BoardView.vue"),
    },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Notification.vue"),
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/users/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormList.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormView.vue"),
    },
    {
        // 관리자 - SMTP관리
        path: "/console/smtp",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
    },
    {
        // 관리자 - 팝업 관리
        path: "/console/popup",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popup/PopupList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너 관리
        path: "/console/banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/index.vue"),
        props: true,
    },
    {
        // 관리자 - 배너 관리
        path: "/console/banner/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너 관리
        path: "/console/banner/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 연어다음캠페인
        path: "/console/uthestar/salmons",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/uthestar/salmons/index.vue"),
    },
    {
        // 관리자 - 연어다음캠페인
        path: "/console/uthestar/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/uthestar/products/SalmonProductList.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = undefined,
            mobileWidth = 1024,
            headerHeight = 50;
        if (window.innerWidth > mobileWidth) {
            headerHeight = 0;
        }

        if (to.hash) {
            return {
                selector: to.hash,
                offset: { x: 0, y: headerHeight },
                behavior: "smooth",
            };
        }
        if (scrollTo != undefined) {
            return {
                selector: scrollTo,
                behavior: "smooth",
            };
        } else {
            return { x: 0, y: 0 }; // Go to the top of the page if no hash
        }
    },
});

router.beforeEach((to, from, next) => {
    axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("accessToken") ? `Bearer ${sessionStorage.getItem("accessToken")}` : "";
    axios.defaults.headers.common["Accept-Language"] = i18n.locale;

    next();
});

export default router;
