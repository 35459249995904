var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer__top py-60 py-lg-80"
  }, [_c('v-container', {
    staticClass: "container--large"
  }, [_c('UScrollTop'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "footer-logo mb-20 mb-lg-40"
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('a', {
    staticClass: "footer-sns footer-sns__blog mr-6 mr-lg-12",
    attrs: {
      "href": "https://blog.naver.com/uthestar",
      "target": "_blank"
    }
  }), _c('a', {
    staticClass: "footer-sns footer-sns__instar",
    attrs: {
      "href": "https://www.instagram.com/uthestarsports/",
      "target": "_blank"
    }
  })])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('p', {
    staticClass: "color-grey mb-14 mb-lg-32"
  }, [_c('strong', {
    staticClass: "font-weight-bold"
  }, [_vm._v("재단법인 유더스타스포츠")]), _c('br'), _vm._v(" Address. 서울특별시 강서구 공항대로 39길 7(구. 등촌동 669-2)"), _c('span', {
    staticClass: "d-none d-xl-inline-block text-center",
    staticStyle: {
      "width": "36px",
      "color": "#ddd"
    }
  }, [_vm._v("l")]), _c('br', {
    staticClass: "d-block d-xl-none"
  }), _vm._v(" Tel. 02-3662-3210"), _c('span', {
    staticClass: "d-none d-xl-inline-block text-center",
    staticStyle: {
      "width": "36px",
      "color": "#ddd"
    }
  }, [_vm._v("l")]), _c('br', {
    staticClass: "d-block d-xl-none"
  }), _vm._v(" Fax. 02-3664-2401"), _c('br'), _vm._v(" 사업자등록번호. 867-82-00369"), _c('span', {
    staticClass: "d-none d-xl-inline-block text-center",
    staticStyle: {
      "width": "36px",
      "color": "#ddd"
    }
  }, [_vm._v("l")]), _c('br', {
    staticClass: "d-block d-xl-none"
  }), _vm._v(" 개인정보책임자. maruyou@uthestar.org ")]), _c('div', {
    staticClass: "d-flex mx-n4 mx-lg-n6 footer-cert-group"
  }, [_c('div', {
    staticClass: "px-4 px-lg-6"
  }, [_c('img', {
    staticClass: "d-block",
    attrs: {
      "src": "/images/common/cert1.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "px-4 px-lg-6"
  }, [_c('img', {
    staticClass: "d-block",
    attrs: {
      "src": "/images/common/cert2.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "px-4 px-lg-6"
  }, [_c('img', {
    staticClass: "d-block",
    attrs: {
      "src": "/images/common/cert3.jpg",
      "alt": ""
    }
  })])])])], 1)], 1)], 1), _c('div', {
    staticClass: "footer__bottom py-22"
  }, [_c('v-container', {
    staticClass: "container--large"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('a', {
    staticClass: "d-block font-weight-bold color-grey mr-10 mr-lg-18",
    attrs: {
      "href": "/about/business"
    }
  }, [_vm._v("재단 소개")]), _c('span', {
    staticStyle: {
      "color": "#ddd"
    }
  }, [_vm._v("l")]), _c('a', {
    staticClass: "d-block font-weight-bold color-grey ml-10 ml-lg-18",
    attrs: {
      "href": "/privacy-policy"
    }
  }, [_vm._v("개인정보처리방침")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('p', {
    staticClass: "color-grey-9"
  }, [_vm._v("Copyright 2022. 재단법인 유더스타스포츠. all rights reserced.")])])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }